import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { userContext } from 'context/userContext';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { windowContext } from 'context/windowsContext';
import { toastContext } from 'context/toastContext';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import VideoView from './views/VideoView';
import CourseView from './views/CourseView';
import VideoList from './components/VideoList';
import Progress from 'components/progress/Progress';
import Resources from './components/Resources';
import ErrorComponent from 'components/error/Error';
import { currencyOptions } from '../components/Step3';
import SingleCourseSkeleton from 'components/skeleton/SingleCourseSkeleton';
import { COURSE_REVIEW_STATUS, COURSE_STATUS } from 'utils/constants';
import { capitalizeFirstLetter } from 'utils/format';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourse,
  ICourseResourcesData,
  IVideo
} from 'query/course-module/dto';
import {
  singleCourseDataQuery,
  adminSingleCourseDataQuery,
  adminCourseResourcesDataQuery,
  courseResourcesDataQuery,
  publicCourseResourcesDataQuery
} from 'query/course-module/queries';
import classes from './SingleCourse.module.scss';
import Divider from 'components/divider/Divider';
import Button from 'components/buttons/Button';
import { difficultyOptions } from '../components/Step1';
import VideoViewFreeCourse from './views/VideoViewFreeCourse';

export const currencyObj = currencyOptions.reduce((acc: any, curr) => {
  acc[curr.value] = {
    name: curr.label,
    symbol: curr.symbol
  };
  return acc;
}, {});

export const renderBreadcrumbs = (
  courseData: ICourse,
  changes: COURSE_CHANGES,
  isAdmin: boolean,
  videoData?: IVideo
) => {
  const { name, slug, purchased, course_relationship, status, review_status } =
    courseData;

  const crumbs = [
    { name: 'Home', link: isAdmin ? '/admin' : '/' },
    { name: name, link: `/courses/${slug}?changes=${changes}` }
  ];

  // 1. User is viewing a course they purchased
  if (course_relationship === COURSE_RELATIONSHIP.PURCHASED) {
    crumbs.splice(1, 0, {
      name: 'Purchased Courses',
      link: `/user/purchased-courses/${
        purchased.active ? 'activated' : 'not-activated'
      }`
    });
  }
  // 2. User is viewing a course they created
  else if (course_relationship === COURSE_RELATIONSHIP.CREATOR) {
    let creatorCrumb = { name: '', link: '' };
    // Published Courses
    if (
      status === COURSE_STATUS.PUBLISHED &&
      changes === COURSE_CHANGES.NO_CHANGES
    )
      creatorCrumb = {
        name: 'Published Courses',
        link: '/user/created-courses/published'
      };
    else {
      switch (review_status) {
        case COURSE_REVIEW_STATUS.INITIAL:
          creatorCrumb = {
            name: 'Draft Courses',
            link: '/user/created-courses/draft'
          };
          break;
        case COURSE_REVIEW_STATUS.IN_REVIEW:
          creatorCrumb = {
            name: 'Submitted Courses',
            link: '/user/created-courses/submitted'
          };
          break;
        case COURSE_REVIEW_STATUS.APPROVED:
          creatorCrumb = {
            name: 'Approved Courses',
            link: '/user/created-courses/approved'
          };
          break;
        case COURSE_REVIEW_STATUS.REJECTED:
          creatorCrumb = {
            name: 'Rejected Courses',
            link: '/user/created-courses/rejected'
          };
          break;
      }
    }
    crumbs.splice(1, 0, creatorCrumb);
  }
  // 3. Regular user / Admin is viewing a non-purchased course
  else {
    let crumb = { name: '', link: '' };
    // User IS Admin
    if (isAdmin) {
      if (courseData.is_highlighted) {
        crumb = {
          name: 'Highlighted',
          link: '/admin/courses/highlighted'
        };
      }
      // Published Courses
      else if (
        status === COURSE_STATUS.PUBLISHED &&
        changes === COURSE_CHANGES.NO_CHANGES
      )
        crumb = {
          name: 'Published',
          link: '/admin/courses'
        };
      else if (
        review_status === COURSE_REVIEW_STATUS.IN_REVIEW ||
        review_status === COURSE_REVIEW_STATUS.APPROVED ||
        review_status === COURSE_REVIEW_STATUS.REJECTED
      ) {
        crumb = {
          name: 'Submitted',
          link: '/admin/courses/submitted'
        };
      } else if (review_status === COURSE_REVIEW_STATUS.INITIAL) {
        crumb = {
          name: 'Drafts',
          link: '/admin/courses/drafts'
        };
      }
      crumbs.splice(1, 0, crumb);
    }
    // User is NOT admin
    else {
      const addedCrumbs = [
        {
          name: 'Courses',
          link: '/courses'
        },
        {
          name: capitalizeFirstLetter(courseData.category.name),
          link: `/category/${courseData.category.slug}`
        }
      ];
      if (!!courseData?.subcategory?._id)
        addedCrumbs.push({
          name: capitalizeFirstLetter(courseData.subcategory.name),
          link: `/category/${courseData.category.slug}/${courseData.subcategory.slug}`
        });
      crumbs.splice(1, 0, ...addedCrumbs);
    }
  }

  if (!!videoData)
    crumbs.push({
      name: videoData.title,
      link: `/courses/${courseData.slug}/${videoData._id}?changes=${changes}`
    });

  return <CustomBreadcrumbs crumbs={crumbs} />;
};

export interface ISingleCourseProps {
  isAdminPage: boolean;
  tab:
    | 'about'
    | 'videos'
    | 'test-questions'
    | 'documents'
    | 'certificate'
    | 'reviews'
    | 'history';
  isVideoPage: boolean;
}

const SingleCourse = ({
  isAdminPage,
  tab,
  isVideoPage
}: ISingleCourseProps) => {
  const navigate = useNavigate();
  const { slug, videoId } = useParams();
  const { search: urlParams } = useLocation();
  // We use state to detect if video is uploaded to start refetching for the new generated thumbnail/video preview
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const { is_public_view, changes } = queryString.parse(urlParams);
  const isCreatorPublicView = is_public_view === 'true';
  const { userData } = useContext(userContext);
  const { setToast } = useContext(toastContext);
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);
  const [isRefetching, setIsRefetching] = useState(false);

  const { isLoading, error, data } = useQuery<boolean, Error, ICourse>({
    ...(isAdminPage || userData?.isAdmin
      ? adminSingleCourseDataQuery(slug as string, `?&changes=${changes}`)
      : singleCourseDataQuery(slug as string, `?&changes=${changes}`)),
    refetchInterval: isRefetching && 15000,
    onError: () => {
      setToast({
        type: 'warning',
        title: 'Redirecting...',
        msg: 'You are navigated to the original course page.',
        autoClose: true
      });
      navigate(`/courses/${slug}?changes=${COURSE_CHANGES.NO_CHANGES}`);
    }
  });

  const {
    isLoading: resourcesLoading,
    error: resourcesError,
    data: resourcesData
  } = useQuery<boolean, Error, ICourseResourcesData>({
    ...(isAdminPage
      ? adminCourseResourcesDataQuery(
          data?._id as string,
          `?&changes=${changes}`
        )
      : (data as ICourse)?.course_relationship === COURSE_RELATIONSHIP.CREATOR
      ? courseResourcesDataQuery(data?._id as string, `?&changes=${changes}`)
      : publicCourseResourcesDataQuery(data?._id as string)),
    enabled: !!data?._id
  });

  useEffect(() => {
    if (
      data &&
      ((isVideoUploaded && !data.thumbnail) ||
        (!isAdminPage &&
          ((data?.purchased?.activate_tx_started_at &&
            !data?.purchased?.activate_tx_confirmed_at) ||
            (data?.purchased?.buy_tx_started_at &&
              !data?.purchased?.buy_tx_confirmed_at))))
    ) {
      return setIsRefetching(true);
    }
    setIsRefetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Video page is only accessible to
  // 1) Creator
  // 2) Admin
  // 3) Users who activated the course
  useEffect(() => {
    if (
      (!!userData?._id || !userData?._id) &&
      !userData?.isAdmin &&
      !!data &&
      !data.free_course &&
      isVideoPage &&
      !data?.purchased?.active &&
      data?.course_relationship !== COURSE_RELATIONSHIP.CREATOR
    ) {
      navigate(`/courses/${slug}?changes=${changes}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoPage, userData?.isAdmin, userData?._id, data]);

  if (isLoading || resourcesLoading) return <SingleCourseSkeleton />;
  if (error || resourcesError) {
    if (error?.message === 'Course not found') {
      navigate('/404');
      return <></>;
    }
    return <ErrorComponent error={error} />;
  }

  const {
    creator_id,
    course_relationship,
    purchased,
    progress,
    time_to_complete,
    difficulty_level
  } = data;

  const isPublic =
    userData && userData._id && userData._id !== ''
      ? creator_id !== userData._id
      : true;

  const courseProps = {
    course: data,
    isVideoPage,
    isAdminPage: isAdminPage,
    isPublic: isPublic || isCreatorPublicView,
    isCourseActivated:
      isAdminPage ||
      !!userData?.isAdmin ||
      (!isPublic && !isCreatorPublicView) ||
      (course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
        purchased?.active)
  };

  return (
    <div id="single-course-page-wrapper" className={classes['wrapper__outer']}>
      {renderBreadcrumbs(
        data,
        changes as COURSE_CHANGES,
        isAdminPage,
        resourcesData.videos.find((video) => video._id === videoId)
      )}
      <div className={classes['wrapper__inner']}>
        <div className={classes['wrapper']}>
          {isVideoPage ? (
            !!data?.free_course ? (
              <VideoViewFreeCourse
                courseData={data}
                view={isLgMobile ? 'video-and-description' : 'video'}
              />
            ) : (
              <VideoView
                courseData={data}
                view={isLgMobile ? 'video-and-description' : 'video'}
              />
            )
          ) : (
            <CourseView
              data={data}
              isAdminPage={isAdminPage}
              videos={resourcesData.videos}
            />
          )}
          {!isVideoPage &&
            course_relationship === COURSE_RELATIONSHIP.PURCHASED &&
            purchased?.active && (
              <Progress
                title="Overall Progress"
                progress={+progress?.toFixed(0) || 0}
              />
            )}
          {!isVideoPage && (
            <div className={classes['course-details']}>
              <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
                {`${difficultyOptions[difficulty_level]?.label} Level`}
              </h5>
              <Divider dir="vertical" />
              <div>
                <Button
                  icon={Arrow}
                  iconRotate={45}
                  iconPosition="right"
                  variant="link-neutral"
                  onClick={() =>
                    navigate(`/courses/${slug}?changes=${changes}`)
                  }
                >
                  Requirements
                </Button>
              </div>
              <Divider dir="vertical" />
              <h5 className={`${classes['u-body1']} ${classes['u-semiBold']}`}>
                {`${time_to_complete} hours to complete`}
              </h5>
              <Divider dir="vertical" />
              <div>
                <Button
                  icon={Arrow}
                  iconRotate={45}
                  iconPosition="right"
                  variant="link-neutral"
                  onClick={() =>
                    navigate(`/courses/${slug}?changes=${changes}`)
                  }
                >
                  Agenda
                </Button>
              </div>
            </div>
          )}
          <Resources
            tab={tab}
            courseProps={courseProps}
            resourcesData={resourcesData}
            setIsVideoUploaded={setIsVideoUploaded}
          />
        </div>
        {!isAdminPage &&
          isVideoPage &&
          course_relationship !== COURSE_RELATIONSHIP.CREATOR &&
          !isLgMobile && (
            <VideoList
              videos={resourcesData.videos}
              courseProps={courseProps}
            />
          )}
      </div>
    </div>
  );
};

SingleCourse.defaultProps = {
  isAdminPage: false,
  isVideoPage: false
};

export default SingleCourse;
